import { OFERTA_RENOVACION } from '~/store/States/global';

export async function getBanners() {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_DOMAIN}/api/wcaas/${process.env.NEXT_PUBLIC_MERCHANT}/espot/Slider_Banners`,
  );
  if (res.ok) {
    return (await res.json()).items;
  }
  console.error('Error getBanners', res);
  return [];
}

export async function getHome() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_DOMAIN}${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/home`)
  if (res.ok) {
    return res.json()
  }
  console.error('Error getHome', res)
  return null
}

export async function getMacroCategories() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_DOMAIN}${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/macrocategories`)
  if (res.ok) {
    return res.json()
  }
  console.error('Error getMacroCategories', res)
  return null
}

export function macroSort(macros, config) {
  return macros.sort((a, b) =>
    ((config.orden_categorias[a.external_referrer] || 100) - (config.orden_categorias[b.external_referrer] || 100))
  )
}

export function macroName(macros) {
  return macros.map((element) => ({
    referrer: element.external_referrer,
    name: element.locales[process.env.NEXT_PUBLIC_LOCALE].name,
  }));
}

export function filtroProductos(lista, planes) {
  return lista.flatMap((prod) => {
    if (prod.type === '6') {
      prod.items = prod.items.filter(item => item.pricing.pospago && (!planes || Object.keys(item.pricing.pospago).some(pl => planes[pl])));
      if (prod.items.length === 0) {
        return [];
      }
    } else if (prod.type === '2') {
      const tipoOferta = prod.attributes.find(attr => attr.identifier === 'attr-conf-tipo-oferta')?.value;
      if (tipoOferta?.toUpperCase() === OFERTA_RENOVACION) {
        return [];
      }
    }
    return [prod];
  });
}

export function ajusteDestacados(products, isMobile) {
  const data = [...products];
  if (!isMobile && products.length && products.length < 4) {
    for (let i = 0; i < 4 - products.length; i += 1) {
      data.push(products[i >= products.length ? products.length - 1 : i]);
    }
  }
  return data;
}
